/* ------------------------------------------------------------ *\
	Link More
\* ------------------------------------------------------------ */

.link-more { font-size: 16px; color: #0072ba; }

@media(max-width: 767px){
	
	/* ------------------------------------------------------------ *\
		Link More
	\* ------------------------------------------------------------ */
	
	.link-more { font-size: 10px; }
}