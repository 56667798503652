/* ------------------------------------------------------------ *\
	Container
\* ------------------------------------------------------------ */



@media(max-width: 992px){
	
	/* ------------------------------------------------------------ *\
		Container
	\* ------------------------------------------------------------ */
	
	.container { width: 100%; }
}