/* ------------------------------------------------------------ *\
	Wrapper
\* ------------------------------------------------------------ */

.wrapper { padding-top: 176px; }

.wrapper .wrapper-inner { position: fixed; width: 100%; top: 0; left: 0; z-index: 10; }

@media(max-width: 1023px){
	
	/* ------------------------------------------------------------ *\
		Wrapper
	\* ------------------------------------------------------------ */
	
	.wrapper { padding-top: 153px; }
}

@media(max-width: 767px){
	
	/* ------------------------------------------------------------ *\
		Wrapper
	\* ------------------------------------------------------------ */
	
	.wrapper { padding-top: 42px; }
}