/* ------------------------------------------------------------ *\
	Copyright
\* ------------------------------------------------------------ */

.copyright { margin: 0; font-size: 15px; color: #fff; }

@media(max-width: 767px){
	
	/* ------------------------------------------------------------ *\
		Copyright
	\* ------------------------------------------------------------ */
	
	.copyright { font-size: 12px; }
}