/* ------------------------------------------------------------ *\
	Widget
\* ------------------------------------------------------------ */

.widgets { padding-left: 0; list-style-type: none; }

.widget { border: 1px solid #dedede; background: #eff1f3; }

.widget + .widget { margin-top: 19px; }

/* ------------------------------------------------------------ *\
	Widget Search
\* ------------------------------------------------------------ */

.widget-search { padding: 19px 19px 20px; }

/* ------------------------------------------------------------ *\
	Widget Downloads
\* ------------------------------------------------------------ */

.widget-downloads { padding: 15px 19px 24px; }

/* ------------------------------------------------------------ *\
	Widget Contacts
\* ------------------------------------------------------------ */

.widget-contacts { padding: 15px 20px 24px; }

@media(max-width: 767px){
	
	/* ------------------------------------------------------------ *\
		Widget
	\* ------------------------------------------------------------ */
	
	.widget + .widget { margin-top: 15px; }

	/* ------------------------------------------------------------ *\
		Widget Search
	\* ------------------------------------------------------------ */
	
	.widget-search { padding: 15px 15px 16px; }

	/* ------------------------------------------------------------ *\
		Widget Downloads
	\* ------------------------------------------------------------ */
	
	.widget-downloads { padding: 12px 15px 19px; }

	/* ------------------------------------------------------------ *\
		Widget Contacts
	\* ------------------------------------------------------------ */
	
	.widget-contacts { padding: 13px 16px; }
}