/* ------------------------------------------------------------ *\
	Filters
\* ------------------------------------------------------------ */

.filters { padding-bottom: 47px; }

.filters .row + .row { padding-top: 9px; }
.filters .row { margin: 0 -5px; }
.filters .col-sm-3 { padding: 0 5px; }

.filters a { display: block; min-height: 51px; padding: 13px 5px; border: 1px solid #dedede; font-size: 16px; font-weight: 700; color: #0072ba; text-align: center; background: #fff; }
.filters a:focus { color: #0072ba; text-decoration: none; }

.filters a:hover,
.filters a.active { color: #fff; text-decoration: none; background: #0072ba; }

@media(max-width: 992px){
	
	/* ------------------------------------------------------------ *\
		Filters
	\* ------------------------------------------------------------ */
	
	.filters a { padding: 15px 5px 15px; font-size: 13px; }
}

@media(max-width: 767px){
	
	/* ------------------------------------------------------------ *\
		Filter
	\* ------------------------------------------------------------ */
	
	.filters { padding-bottom: 29px; }

	.filters .row { float: left; width: 50%; margin: 0; padding-left: 4px; }
	.filters .row .col-sm-3 { padding: 0; }
	.filters .row + .row { padding: 0 0 0 4px; }
	.filters .col-sm-3 + .col-sm-3 { padding-top: 5px; }

	.filters a { min-height: 0; padding: 7px 3px 6px; font-size: 8px; }

	.filters-inner { margin-left: -4px; }
	.filters-inner:after { @include clear }
}