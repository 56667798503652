/* ------------------------------------------------------------ *\
	Search
\* ------------------------------------------------------------ */

.search { position: relative; max-width: 750px; z-index: 1; }
.search:after { content: ''; display: block; width: 100%; clear: both; }

.input-group-addon { float: none; display: block; width: auto; padding: 0 0 15px; border: 0; font-size: 24px; color: #575757; text-align: left; }
.form-control { display: block; width: 100%; max-width: 750px; height: 46px; padding: 0 78px 0 17px; border: 1px solid #b1b1b1; border-radius: 2px; font-size: 16px; color: #9b9b9b; }
.input-group { position: static; display: block; }
.input-group .form-control:not(:first-child):not(:last-child) { display: block; border-radius: 2px; }
.search-btn { position: absolute; width: 66px; height: 43px; bottom: 3px; right: 1px; padding: 1px 0; border: 0; border-radius: 2px; box-shadow: 0 3px 0 #004a79; background: #0072ba; z-index: 2; transition: .1s; -webkit-appearance: none; -moz-appearance: none; appearance: none; }
.search-btn:hover { height: 46px; bottom: 0; box-shadow: none; }
.input-group-addon:first-child { background: none; }

/* ------------------------------------------------------------ *\
	Search Secondary
\* ------------------------------------------------------------ */

.search-secondary { position: relative; }

.form-control-secondary { width: 100%; height: 46px; padding: 0 77px 0 16px; border: 1px solid #b1b1b1; border-radius: 2px; font-size: 16px; color: #9b9b9b; }


@media(max-width: 767px){
	
	/* ------------------------------------------------------------ *\
		Search
	\* ------------------------------------------------------------ */
	
	.input-group-addon { padding-bottom: 7px; font-size: 12px; }
	.form-control { height: 23px; padding: 0 45px 0 8px; font-size: 10px; }
	
	.search-btn { width: 34px; height: 20px; }
	.search-btn:hover { height: 23px; }
	.search-btn i { margin-top: -3px; }

	/* ------------------------------------------------------------ *\
		Search Secondary
	\* ------------------------------------------------------------ */
	
	.form-control-secondary { height: 37px; font-size: 12px; }

	.search-btn-secondary { width: 54px; height: 34px; }
	.search-btn-secondary:hover { height: 37px; }
	.search-btn-secondary i { width: 20px; height: 20px; }
}