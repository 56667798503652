/* ------------------------------------------------------------ *\
	Ico
\* ------------------------------------------------------------ */

.ico-flag { display: inline-block; width: 22px; height: 22px; vertical-align: middle; background: url(images/sprite/ico-flag.png) no-repeat 0 0; }
.ico-prev { display: inline-block; width: 12px; height: 19px; vertical-align: middle; background: url(images/sprite/ico-prev.png) no-repeat 0 0; }
.ico-next { display: inline-block; width: 12px; height: 19px; vertical-align: middle; background: url(images/sprite/ico-next.png) no-repeat 0 0; }
.ico-magnifying-glass { display: inline-block; width: 25px; height: 25px; vertical-align: middle; background: url(images/sprite/ico-magnifying-glass.png) no-repeat 0 0; }
.ico-facebook { display: inline-block; width: 10px; height: 20px; vertical-align: middle; background: url(images/sprite/ico-facebook.png) no-repeat 0 0; }
.ico-linkedin { display: inline-block; width: 18px; height: 18px; vertical-align: middle; background: url(images/sprite/ico-linkedin.png) no-repeat 0 0; }
.ico-xing { display: inline-block; width: 16px; height: 20px; vertical-align: middle; background: url(images/sprite/ico-xing.png) no-repeat 0 0; }
.ico-arrow-right { display: inline-block; width: 9px; height: 14px; vertical-align: middle; background: url(images/sprite/ico-arrow-right.png) no-repeat 0 0; }
.ico-facebook-secondary { display: inline-block; width: 17px; height: 17px; vertical-align: middle; background: url(images/sprite/ico-facebook-secondary.png) no-repeat 0 0; }
.ico-linkedin-secondary { display: inline-block; width: 18px; height: 18px; vertical-align: middle; background: url(images/sprite/ico-linkedin-secondary.png) no-repeat 0 0; }
.ico-xing-secondary { display: inline-block; width: 16px; height: 20px; vertical-align: middle; background: url(images/sprite/ico-xing-secodnary.png) no-repeat 0 0; }
.ico-arrow-right-secondary { display: inline-block; width: 6px; height: 9px; vertical-align: middle; background: url(images/sprite/ico-arrow-right-secondary.png) no-repeat 0 0; }
.ico-download { display: inline-block; width: 25px; height: 21px; vertical-align: middle; background: url(images/sprite/ico-download.png) no-repeat 0 0; }
.ico-triangle { display: inline-block; width: 13px; height: 16px; vertical-align: middle; background: url(images/sprite/ico-triangle.png) no-repeat 0 0; }
.ico-list { display: inline-block; width: 18px; height: 23px; vertical-align: middle; background: url(images/sprite/ico-list.png) no-repeat 0 0; }
.ico-pdf { display: inline-block; width: 23px; height: 29px; vertical-align: middle; background: url(images/sprite/ico-pdf.png) no-repeat 0 0; }

.facebook-icon { display: inline-block; width: 23px; height: 30px; vertical-align: middle; background: url(images/facebook.svg) no-repeat 0 0; background-size: 100% 100%; }
.linkedin-icon { display: inline-block; width: 22px; height: 22px; vertical-align: middle; background: url(images/linkedin-icon.svg) no-repeat 0 0; background-size: 100% 100%; }
.xing-icon { display: inline-block; width: 24px; height: 24px; vertical-align: middle; background: url(images/xing-icon.svg) no-repeat 0 0; background-size: 100% 100%; }

@media(max-width: 767px){
	
	/* ------------------------------------------------------------ *\
		Ico
	\* ------------------------------------------------------------ */
	
	.ico-pdf { width: 15px; height: 19px; 
		-webkit-background-size: 100% 100%; 
		        background-size: 100% 100%; 
	}

	.ico-magnifying-glass { width: 13px; height: 13px; 
		-webkit-background-size: 100% 100%; 
		        background-size: 100% 100%; 
	}

	.ico-facebook { width: 8px; height: 16px; 
		-webkit-background-size: 100% 100%; 
		        background-size: 100% 100%; 
	}

	.ico-linkedin { width: 15px; height: 15px; 
		-webkit-background-size: 100% 100%; 
		        background-size: 100% 100%; 
	}

	.ico-xing { width: 13px; height: 16px; 
		-webkit-background-size: 100% 100%; 
		        background-size: 100% 100%; 
	}

	.ico-facebook-secondary { width: 14px; height: 14px; 
		-webkit-background-size: 100% 100%; 
		        background-size: 100% 100%; 
	}

	.ico-linkedin-secondary { width: 15px; height: 15px; 
		-webkit-background-size: 100% 100%; 
		        background-size: 100% 100%; 
	}

	.ico-xing-secondary { width: 13px; height: 16px; 
		-webkit-background-size: 100% 100%; 
		        background-size: 100% 100%; 
	}

	.ico-download { width: 20px; height: 17px; 
		-webkit-background-size: 100% 100%; 
		        background-size: 100% 100%; 
	}

	.ico-triangle { width: 10px; height: 13px; 
		-webkit-background-size: 100% 100%; 
		        background-size: 100% 100%; 
	}

	.ico-list { width: 14px; height: 18px; 
		-webkit-background-size: 100% 100%; 
		        background-size: 100% 100%; 
	}
}

@media only screen and ( -webkit-min-device-pixel-ratio: 2 ), only screen and ( min-device-pixel-ratio: 2 ), only screen and ( min-resolution: 192dpi ) {
	
	/* ------------------------------------------------------------ *\
		Ico
	\* ------------------------------------------------------------ */
		
	.ico-arrow-right { background: url(images/sprite/ico-arrow-right@2x.png) no-repeat 0 0; 
		-webkit-background-size: 100% 100%; 
		        background-size: 100% 100%; 
	}

	.ico-arrow-right-secondary { background: url(images/sprite/ico-arrow-right-secondary@2x.png) no-repeat 0 0; 
		-webkit-background-size: 100% 100%; 
		        background-size: 100% 100%; 
	}

	.ico-arrow-down { background: url(images/sprite/ico-arrow-down@2x.png) no-repeat 0 0; 
		-webkit-background-size: 100% 100%; 
		        background-size: 100% 100%; 
	}
}