/* ------------------------------------------------------------ *\
	Socials
\* ------------------------------------------------------------ */

.socials ul { margin: 0; padding: 0; list-style-type: none; }
.socials ul:after { @include clear}
.socials li { float: left; }
.socials li + li { margin-left: 34px; }
.socials a { position: relative; display: block; }
.socials a:hover { opacity: .7; }
.socials .icon { position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); -webkit-transform: translate(-50%, -50%); }

.socials .svg-item { position: relative; }
.socials .facebook { display: block; width: 10px; height: 20px; }
.socials .linkedin { display: block; width: 18px; height: 20px; }
.socials .xing { display: block; width: 16px; height: 20px; }

#xing-icon,
#linkedin-square-rounded-icon,
#facebook-icon { fill: #fff; }

/* ------------------------------------------------------------ *\
	Socials Secondary
\* ------------------------------------------------------------ */

.socials-secondary ul { padding: 0; margin: 0; list-style-type: none; }
.socials-secondary li + li { margin-top: 14px; }
.socials-secondary i { position: relative; width: 18px; top: -2px; margin-right: 12px; }
.socials-secondary a { display: block; font-size: 15px; letter-spacing: 0.02em; color: #fff; text-decoration: none; }
.socials-secondary a:hover { opacity: .7; }

@media(max-width: 767px){

	/* ------------------------------------------------------------ *\
		Socials
	\* ------------------------------------------------------------ */
	
	.socials li + li { margin-left: 25px; }

	.socials .facebook .facebook-icon { width: 18px !important; }
	.socials .linkedin .linkedin-icon { width: 18px !important; }
	.socials .xing .xing-icon { width: 17px !important; }
	
	/* ------------------------------------------------------------ *\
		Socials Secondary
	\* ------------------------------------------------------------ */
	
	.socials-secondary { padding: 7px 0 22px; }

	.socials-secondary li { position: relative; padding-left: 28px; }
	.socials-secondary li + li { margin-top: 11px; }
	.socials-secondary i { position: absolute; top: 0; left: 0; }
	.socials-secondary .ico-facebook-secondary { width: 14px; }
	.socials-secondary .ico-linkedin-secondary { width: 15px; }
	.socials-secondary .ico-xing-secondary { width: 13px; }
	.socials-secondary a { font-size: 12px; }
}