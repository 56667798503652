/* ------------------------------------------------------------ *\
	Nav
\* ------------------------------------------------------------ */

.navbar-default .navbar-collapse { padding: 0; }

.nav-mobile-btn { display: none; }

.navbar { position: relative; margin: 0; border: 0; border-radius: 0; box-shadow: 0 4px 6px rgba(0, 0, 0, .4); background: #fff; z-index: 6; }
.navbar-link { padding: 0; font-size: 20px; font-weight: 500; color: #575757; }
.navbar-link.active { background: none; }
.navbar-collapse { position: relative; }

.navbar-nav > li { position: static; }
.navbar-nav > li > a { max-height: 78px; padding: 29px 0 24px; border-bottom: 5px solid transparent; }
.navbar-default .navbar-nav > li > a { color: #575757; }
.navbar-default .navbar-nav > li + li { margin-left: 50px; }
.navbar-default .navbar-nav > .active > a:hover { background: none; }

.navbar-default .navbar-nav > .open > a, 
.navbar-default .navbar-nav > .open > a:focus, 
.navbar-default .navbar-nav > .open > a:hover { border-color: transparent; color: #000; background: none; }

.navbar-default .navbar-nav > .open > a:before, 
.navbar-default .navbar-nav > .open > a:focus:before,
.navbar-default .navbar-nav > .open > a:hover:before { content: ''; position: absolute; width: 0; height: 0; top: 107%; left: 50%; transform: translateX(-50%); border-style: solid; border-width: 18px 11px 0 11px; border-color: #ffffff transparent transparent transparent; }

.navbar-default .navbar-nav > li > a:hover,
.navbar-default .navbar-nav > .active > a { border-bottom: 5px solid #0072b0; color: #0072ba; background: none; }

/* ------------------------------------------------------------ *\
	Navbar Right
\* ------------------------------------------------------------ */

.navbar-right { position: relative; margin-right: 0; }

.navbar-right li a { padding-bottom: 22px; font-size: 14px; font-weight: 700; letter-spacing: 0.03em; color: #575757; }
.navbar-right li a:after { content: ''; position: relative; display: inline-block; width: 12px; height: 7px; top: -1px; margin-left: 7px; background: url(images/sprite/ico-arrow-down@2x.png) no-repeat 0 0; 
	-webkit-background-size: 100% 100%; 
	        background-size: 100% 100%; 
}
.navbar-right li i { position: relative; top: -1px; margin-right: 4px; }

/* ------------------------------------------------------------ *\
	Dropdown Lang
\* ------------------------------------------------------------ */

.navbar-nav > li > .dropdown-lang { padding: 0; border-radius: 8px; overflow: hidden; text-align: center; }
.navbar-nav > li > .dropdown-lang a { padding: 20px 0; }
.navbar-nav > li > .dropdown-lang a:after { display: none; }

/* ------------------------------------------------------------ *\
	Nav Pils
\* ------------------------------------------------------------ */

.nav-pills { position: fixed; top: 196px; right: 0; z-index: 20; }

.nav-pills > li { float: none; display: block; margin: 0; }
.nav-pills > li + li { margin: 17px 0 0; }
.nav-pills > li > a { padding: 0; border-radius: 4px 0 0 4px; }
.nav-pills > li.active > a { border-radius: 3px 0 0 3px; background: #ff9500; }

.nav-pills > li > a:hover,
.nav-pills > li.active > a:hover { color: #fff; background: #a66100; }

.nav-pills a { width: 128px; height: 43px; padding: 0; border-radius: 3px 0 0 3px; box-shadow: 0 3px 0 #a66100; font-size: 16px; line-height: 43px; font-weight: 700; text-align: center; color: #fff; background: #ff9500; }

/* ------------------------------------------------------------ *\
	Dropdown Menu
\* ------------------------------------------------------------ */

.dropdown-menu { width: 100%; top: 107px; padding: 49px 50px; border: 0; }

.dropdown-menu img { width: 100%; height: auto; }

.dropdown-menu-title { font-size: 20px; font-weight: 700; color: #0072ba; padding: 31px 0 20px; margin: 0; }

.dropdown-menu .row { margin: 0 -32px; }
.dropdown-menu .col-sm-3 { padding: 0 32px; }

/* ------------------------------------------------------------ *\
	Dropdown Menu Secondary
\* ------------------------------------------------------------ */

.dropdown-menu-secondary .dropdown-menu-title { padding-top: 0; }

@media(max-width: 1023px){
	
	/* ------------------------------------------------------------ *\
		Navbar
	\* ------------------------------------------------------------ */
	
	.navbar-link { font-size: 16px; }

	.navbar-nav > li > a { padding: 15px 0; }
	.navbar-right li a { padding: 15px 0 13px; }
	.navbar-default .navbar-nav > li + li { margin-left: 30px; }

	/* ------------------------------------------------------------ *\
		Dropdown Menu
	\* ------------------------------------------------------------ */
	
	.dropdown-menu { top: 80px; }

	/* ------------------------------------------------------------ *\
		Nav Pills
	\* ------------------------------------------------------------ */
	
	.nav-pills { top: 160px; }
}

@media(max-width: 767px){

	/* ------------------------------------------------------------ *\
		Nav
	\* ------------------------------------------------------------ */
	
	.nav-mobile-btn { display: block; width: auto; height: 29px; padding: 0 9px; margin: 10px 15px; font-size: 12px; line-height: 27px; letter-spacing: 0.01em; color: #0072ba; background: #f5f7fa; 
		-webkit-box-shadow: 0 3px 5px rgba(0, 0, 0, .1); 
		        box-shadow: 0 3px 5px rgba(0, 0, 0, .1); 
	}
	.nav-mobile-btn i { float: right; margin-top: 8px; }

	.navbar-default .navbar-nav > li > a:focus { border: 1px; }
	.navbar-default .navbar-nav > li > a:hover { border: 0; border-bottom: 1px solid #dcdee1; }

	.navbar-default .navbar-nav > .open > a:before, 
	.navbar-default .navbar-nav > .open > a:focus:before, 
	.navbar-default .navbar-nav > .open > a:hover:before { display: none; }

	.navbar { position: static; min-height: 0; }
	.navbar .navbar-toggle { position: absolute; top: 10px; right: 10px; z-index: 5; }
	.navbar .navbar-nav { position: absolute; width: auto; top: 10px; left: 15px; right: 15px; margin: 0; border: 1px solid #dddfe2; visibility: hidden; background: #f5f7fa; z-index: 2; }
	.navbar .navbar-nav.active { visibility: visible; }
	.navbar .navbar-nav li + li { margin: 0; }
	.navbar .navbar-nav li a { padding: 9px; border-bottom: 1px solid #dcdee1; font-size: 12px; color: #0072ba; }
	.navbar .navbar-nav li.active a { border: 0; border-bottom: 1px solid #dcdee1; }
	.navbar .navbar-collapse { position: absolute; width: 100%; height: auto; top: 42px; left: 0; height: 5000px; margin: 0; z-index: 2; background: #fff; }
	.navbar .navbar-nav.navbar-right { position: absolute; display: block; width: auto; top: 50px; right: 0; left: auto; border: 0; visibility: visible; background: none; z-index: 1; }
	.navbar .navbar-nav.navbar-right a { padding: 9px 15px; border: 0; font-size: 12px; }
	.navbar .navbar-nav.navbar-right a:hover { border: 0; }
	.navbar .navbar-nav.navbar-right .active a { border: 0; }
	.navbar .navbar-nav.navbar-right a:after { margin-left: 4px; }

	.navbar-default .navbar-toggle { padding: 0; margin: 0; border: 0; border-radius: 0; background: none; }
	.navbar-default .navbar-toggle .icon-bar { width: 22px; height: 5px; border: 0; border-radius: 2px; background: #fff; }

	.navbar-default .navbar-toggle:focus,
	.navbar-default .navbar-toggle:hover { background: none; }
	
	/* ------------------------------------------------------------ *\
		Nav Pills
	\* ------------------------------------------------------------ */
	
	.nav-pills { display: none; position: static; padding: 14px 15px; }
	
	.navbar .nav-pills { display: block; }
	
	.nav-pills > li > a:hover, 
	.nav-pills > li.active > a:hover { color: #ff9500; text-decoration: underline; background: none; }

	.nav-pills > li.active > a { color: #ff9500; background: none; }
	.nav-pills > li + li { margin-top: 18px; }

	.nav-pills a { height: auto; font-size: 12px; line-height: 1; color: #ff9500; text-align: left; background: none; 
		-webkit-box-shadow: none; 
		        box-shadow: none; 
	}

	/* ------------------------------------------------------------ *\
		Dropdown Menu Big
	\* ------------------------------------------------------------ */
	
	.dropdown-menu-big { padding: 10px; }
	.dropdown-menu-big li .list-links a { padding: 5px 0; border: 0; }
	.dropdown-menu-big .col-sm-3 + .col-sm-3 { padding-top: 20px; }

	.dropdown-menu-title { font-size: 18px; padding: 5px 0; }

	/* ------------------------------------------------------------ *\
		Drodpwon Lang
	\* ------------------------------------------------------------ */
	
	.navbar-nav .open .dropdown-lang { position: absolute; top: 100%; left: auto; right: 0; }
}