/* ------------------------------------------------------------ *\
	Section Search
\* ------------------------------------------------------------ */

.section-search { padding: 53px 0 58px; }

/* ------------------------------------------------------------ *\
	Section Features
\* ------------------------------------------------------------ */

.section-features-head { padding-bottom: 26px; }
.section-features .section-features-head h1 { font-size: 30px; font-weight: 700; letter-spacing: 0.03em; color: #0072ba; margin-bottom: 5px; }

.section-features-head-secondary { padding: 10px 0 3px; }

.section-features .section-features-head p { font-size: 24px; color: #575757; }
.section-features .section-features-head a { float: right; color: #0072ba; }

/* ------------------------------------------------------------ *\
	Section Search Secondary
\* ------------------------------------------------------------ */

.section-search-secondary { padding: 29px 0 27px; padding-bottom: 27px; }

/* ------------------------------------------------------------ *\
	Section Products
\* ------------------------------------------------------------ */

.section-products h2 { margin-bottom: 5px; }

/* ------------------------------------------------------------ *\
	Section Cols
\* ------------------------------------------------------------ */

.section-cols { margin-top: -4px; }

.section-cols .section-cols-head h1 { font-size: 30px; font-weight: 700; letter-spacing: 0.03em; color: #0072ba; margin: 0; }
.section-cols-head { padding-bottom: 13px; }

@media(max-width: 992px){
	
	/* ------------------------------------------------------------ *\
		Section Search
	\* ------------------------------------------------------------ */
	
	.section-search { padding: 20px 0; }

	/* ------------------------------------------------------------ *\
		Section Features
	\* ------------------------------------------------------------ */
	
	.section-features .section-features-head p { font-size: 18px; }
}

@media(max-width: 767px){

	/* ------------------------------------------------------------ *\
		Section Cols
	\* ------------------------------------------------------------ */
	
	.section-cols { margin-top: 0; }
	
	.section-cols .section-cols-head h1 { font-size: 17px; }
	.section-cols .col-sm-3 + .col-sm-3 { padding-top: 4px; }
	
	/* ------------------------------------------------------------ *\
		Section Search
	\* ------------------------------------------------------------ */
	
	.section-search { padding: 18px 0 0; margin-bottom: -2px; }

	/* ------------------------------------------------------------ *\
		Section Features
	\* ------------------------------------------------------------ */
	
	.section-features .section-features-head h1 { font-size: 17px; margin-bottom: 4px; }

	.section-features-head { padding-bottom: 9px; }
	.section-features .col-sm-4 + .col-sm-4 { padding-top: 14px; }
	
	.section-features .section-features-head p { font-size: 12px; line-height: 1.5; }
	.section-features .section-features-head a { float: none; display: block; }

	/* ------------------------------------------------------------ *\
		Section Search Secondary
	\* ------------------------------------------------------------ */
	
	.section-search-secondary { padding: 9px 0; }

	/* ------------------------------------------------------------ *\
		Section Products
	\* ------------------------------------------------------------ */
	
	.section-products .section-features-head { padding-bottom: 0; }
}