/* ------------------------------------------------------------ *\
	Logo
\* ------------------------------------------------------------ */

.logo { display: inline-block; width: 170px; height: 48px; background: url(images/sprite/logo.png) no-repeat 0 0; font-size: 0; line-height: 0; text-indent: -4004px; }

@media(max-width: 767px){
	
	/* ------------------------------------------------------------ *\
		Logo
	\* ------------------------------------------------------------ */
	
	.logo { width: 85px; height: 25px; 
		-webkit-background-size: 100% 100%; 
		        background-size: 100% 100%; 
	}
}