/* ------------------------------------------------------------ *\
	Slider
\* ------------------------------------------------------------ */

.slider { position: relative; }
.slider.active:before { content: ''; position: absolute; top: 0; left: 0; width: 100%; height: 100%; background: rgba(74, 82, 93, .85); z-index: 100; }

.slider .col-sm-6 { padding-left: 0; }

.slider ul { padding: 0; margin: 0; }
.slider img { width: 100%; height: auto; }
.slider .slide span { position: absolute; top: 30px; left: 0; padding: 5px 77px 4px 30px; border-radius: 0 3px 3px 0; font-size: 16px; font-weight: 500; color: #fff; background: #4fbd7e; }
.slider h1 { font-size: 24px; line-height: 1.42; font-weight: 700; color: #0070ad; margin: 16px 0 -1px; }
.slider p { margin-bottom: 8px; font-size: 24px; line-height: 1.42; color: #000; }
.slider .slide-inner a { font-size: 16px; color: #0072ba; }

.slide { position: relative; }
.slides { list-style-type: none; }
.slide-content { position: absolute; width: 100%; left: 0; bottom: 28px; border-radius: 3px; }
.slide-inner { position: relative; padding: 58px 30px 25px; background: #fff; }
.slider-prev { position: absolute; width: 45px; height: 45px; top: 50%; left: -95px; margin-top: -22px; border-radius: 2px; text-align: center; background: #0072ba; }
.slider-next { position: absolute; width: 45px; height: 45px; top: 50%; right: -95px; margin-top: -22px; border-radius: 2px; text-align: center; background: #0072ba; }
.owl-item { backface-visibility: hidden; }
.owl-carousel .owl-item .slide-mobile-image { display: none; }

.slider-prev i,
.slider-next i { position: absolute; top: 50%; left: 50%; 
	-webkit-transform: translate(-50%, -50%); 
	    -ms-transform: translate(-50%, -50%); 
	     -o-transform: translate(-50%, -50%); 
	        transform: translate(-50%, -50%); 
}

.slider-prev:hover,
.slider-next:hover { opacity: .7; }

.slider-actions { position: absolute; width: 100%; top: 50%; left: 0; z-index: 20; }
.slider-actions .container { position: relative; }

@media(max-width: 1650px){
	
	/* ------------------------------------------------------------ *\
		Slider
	\* ------------------------------------------------------------ */
	
	.slider-actions { top: auto; bottom: 10px; }
	.slider-next { margin: 0; top: auto; bottom: 0; right: 15px; }
	.slider-prev { margin: 0; top: auto; bottom: 0; left: auto; right: 65px; }
}

@media(max-width: 1200px){
	
	/* ------------------------------------------------------------ *\
		Slider
	\* ------------------------------------------------------------ */
	
	.slide-image { position: relative; height: 367px; max-height: 367px; overflow: hidden; }

	.owl-carousel .owl-item img,
	.slide-image img { position: absolute; width: 1200px; height: 367px; top: 50%; left: 50%; transform: translate(-50%, -50%); -webkit-transform: translate(-50%, -50%); }
}

@media(max-width: 767px){
	
	/* ------------------------------------------------------------ *\
		Slider
	\* ------------------------------------------------------------ */

	.slider.active:before { display: none; }
	
	.slider .slide span { top: 18px; padding: 1px 46px 3px 18px; font-size: 10px; }
	.slider p { margin-bottom: 4px; font-size: 14px; line-height: 1.43; }
	.slider a { font-size: 10px; }

	.slider-actions { display: none; }
	.slide-content { position: static; margin-top: -28px; }

	.slide-inner { padding: 47px 18px 12px; }
	.slide-inner h1 { font-size: 14px; margin-top: 0; margin: -3px 0 2px; }

	.slide-image { height: auto; }

	.slider .col-sm-6 { padding: 0; }

	.owl-carousel .owl-item .slide-mobile-image { display: block; }

	.owl-carousel .owl-item img,
	.slide-image img { position: static; width: 100%; height: auto; display: none; 
		-webkit-transform: translate(0%, 0%); 
		    -ms-transform: translate(0%, 0%); 
		     -o-transform: translate(0%, 0%); 
		        transform: translate(0%, 0%); 
	}
}