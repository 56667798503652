/* ------------------------------------------------------------ *\
	Slogan
\* ------------------------------------------------------------ */

.slogan { margin-bottom: 0; font-size: 24px; font-weight: 300; letter-spacing: 0.03em; color: #fff; }

@media(max-width: 767px){
	
	/* ------------------------------------------------------------ *\
		Slogan
	\* ------------------------------------------------------------ */
	
	.slogan { font-size: 12px; }
}