/* ------------------------------------------------------------ *\
	Header
\* ------------------------------------------------------------ */

.header { padding: 10px 0 20px; background: #0072ba; }

.header .slogan { float: right; padding-top: 34px; }
.header .logo { float: left; margin-top: 15px; }

@media(max-width: 767px){
	
	/* ------------------------------------------------------------ *\
		Header
	\* ------------------------------------------------------------ */
	
	.header { position: relative; padding: 8px 0; z-index: 3; 
		-webkit-box-shadow: 0 4px 6px rgba(0, 0, 0, .4); 
		        box-shadow: 0 4px 6px rgba(0, 0, 0, .4); 
	}

	.header .logo { float: left; margin-top: 0; }
	.header .slogan { padding: 9px 37px 0 0; }
	.header .container { padding: 0 10px; }
}