/* ------------------------------------------------------------ *\
	Download
\* ------------------------------------------------------------ */

.downloads h1 { font-size: 24px; font-weight: 500; letter-spacing: 0; color: #0072ba; margin: 0 0 28px; }

.downloads { list-style-type: none; padding: 0; margin: 0; }

.download-item + .download-item { margin-top: 28px; }

.download-item a { position: relative; display: block; padding-left: 41px; font-size: 16px; font-weight: 500; color: #000; }
.download-icon-holder { position: absolute; width: 25px; top: 0; left: 0; text-align: center; }

@media(max-width: 767px){
	
	/* ------------------------------------------------------------ *\
		Download
	\* ------------------------------------------------------------ */

	.download-item + .download-item { margin-top: 23px; }
	
	.downloads h1 { font-size: 16px; margin-bottom: 26px; }
	.download-item a { padding-left: 33px; font-size: 12px; }
	.download-icon-holder { top: -2px; }
}