/* ------------------------------------------------------------ *\
	Breadcrumbs
\* ------------------------------------------------------------ */

.breadcrumbs { padding-top: 14px; font-size: 16px; letter-spacing: 0.03em; color: #9f9f9f; }

.breadcrumbs a { color: #9f9f9f; }
.breadcrumbs span { display: inline-block; margin: 0 4px 0 3px; }

/* ------------------------------------------------------------ *\
	Breadcrumbs Secondary
\* ------------------------------------------------------------ */

.breadcrumbs-secondary { padding-bottom: 21px; }

@media(max-width: 992px){
	
	/* ------------------------------------------------------------ *\
		Breadcrumbs
	\* ------------------------------------------------------------ */
	
	.breadcrumbs { margin-bottom: 10px; }
}

@media(max-width: 767px){
	
	/* ------------------------------------------------------------ *\
		Breadcrumbs
	\* ------------------------------------------------------------ */
	
	.breadcrumbs { padding-top: 5px; margin-bottom: 0; font-size: 10px; }


	/* ------------------------------------------------------------ *\
		Breadcrumbs Secondary
	\* ------------------------------------------------------------ */
	
	.breadcrumbs-secondary { padding-bottom: 0; }
}