/* ------------------------------------------------------------ *\
	Fonts
\* ------------------------------------------------------------ */

@font-face {
    font-family: 'Helvetica';
    src: url('fonts/helveticaneue-bold-webfont.eot');
    src: url('fonts/helveticaneue-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('fonts/helveticaneue-bold-webfont.woff') format('woff'),
         url('fonts/helveticaneue-bold-webfont.ttf') format('truetype'),
         url('fonts/helveticaneue-bold-webfont.svg#HelveticaNeueRegular') format('svg');
    font-weight: 700;
    font-style: normal;
}

@font-face {
  font-family: 'Helvetica';
  src: url('fonts/HelveticaNeue-Light.eot?#iefix') format('embedded-opentype'),
	   url('fonts/HelveticaNeue-Light.otf')  format('opentype'),
	   url('fonts/HelveticaNeue-Light.woff') format('woff'),
	   url('fonts/HelveticaNeue-Light.ttf')  format('truetype'),
	   url('fonts/HelveticaNeue-Light.svg#HelveticaNeue-Light') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
    font-family: 'Helvetica';
    src: url('fonts/helveticaneue-light-webfont.eot');
    src: url('fonts/helveticaneue-light-webfont.eot?#iefix') format('embedded-opentype'),
         url('fonts/helveticaneue-light-webfont.woff') format('woff'),
         url('fonts/helveticaneue-light-webfont.ttf') format('truetype'),
         url('fonts/helveticaneue-light-webfont.svg#HelveticaNeueRegular') format('svg');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Helvetica';
    src: url('fonts/helveticaneue-medium-webfont.eot');
    src: url('fonts/helveticaneue-medium-webfont.eot?#iefix') format('embedded-opentype'),
         url('fonts/helveticaneue-medium-webfont.woff') format('woff'),
         url('fonts/helveticaneue-medium-webfont.ttf') format('truetype'),
         url('fonts/helveticaneue-medium-webfont.svg#HelveticaNeueRegular') format('svg');
    font-weight: 500;
    font-style: normal;
}
