/* ------------------------------------------------------------ *\
	Footer
\* ------------------------------------------------------------ */

.footer { position: relative; padding-top: 51px; background: #62686e; }
.footer:after { content: ''; position: absolute; width: 100%; height: 1px; left: 0; bottom: -1px; background: #0072ba; }

.footer img { width: 100%; height: auto; }

.footer h1 { font-size: 16px; font-weight: 700; letter-spacing: 0.1em; color: #fff; text-transform: uppercase; margin: 10px 0 22px; }
.footer-content { padding-bottom: 37px; }
.footer-nav { margin-bottom: 59px; }
.footer-nav:after { @include clear }
.footer-nav-holder { float: left; width: 100%; max-width: 180px; padding: 0; margin: 0; list-style-type: none; }
.footer .footer-nav a { font-size: 15px; color: #fff; }
.footer-nav-item { margin-bottom: 14px; }
.footer-col { float: left; width: 100%; max-width: 180px; }
.footer-image { padding-top: 5px; }
.footer-link { display: inline-block; padding: 16px 0 0; border-bottom: 1px solid #fff; font-size: 15px; color: #fff; }
.footer-link:hover { border-color: transparent; color: #fff; text-decoration: none; }
.footer .col-sm-4:last-child p { padding-top: 12px; font-size: 15px; line-height: 1.6; color: #fff; }
.footer-bar { padding: 13px 0 14px; background: #0072ba; }
.footer-inner:after { @include clear }

.footer-nav a:focus,
.footer-nav a:hover { color: #fff; }

.utility { font-size: 15px; line-height: 24px; color: #fff; }
.utility a { color: #fff; }

@media(max-width: 992px){
	
	/* ------------------------------------------------------------ *\
		Footer
	\* ------------------------------------------------------------ */
	
	.footer { padding-top: 20px; }
	
	.footer-content { padding-bottom: 15px; }
}

@media(max-width: 767px){
	
	/* ------------------------------------------------------------ *\
		Footer
	\* ------------------------------------------------------------ */
	
	.footer { padding-top: 19px; }
	
	.footer h1 { font-size: 12px; margin-bottom: 16px; }
	.footer-inner { padding-bottom: 20px; }
	
	.footer-nav { margin-bottom: 16px; }
	.footer-nav-holder { max-width: 143px; }
	.footer-image { padding-top: 0; }
	.footer-nav-item { margin-bottom: 8px; }
	.footer .footer-nav a { font-size: 12px; }
	.footer-col { max-width: 143px; }
	.footer-bar { padding: 11px 0; }
	.footer-link { padding-top: 10px; font-size: 12px; }
	.footer .col-sm-4:last-child p { font-size: 12px; line-height: 1.58; }
	.footer-content { padding-bottom: 40px; }

	.utility { font-size: 12px; line-height: 1.58; }
}