/* ------------------------------------------------------------ *\
	Utilities
\* ------------------------------------------------------------ */

.utilities h1 { font-size: 16px; font-weight: 700; letter-spacing: 0; color: #0072ba; margin-bottom: 13px; }

.list-utilities a { display: block; font-size: 16px; color: #000; }
.list-utilities-secondary a { position: relative; padding-left: 29px; }
.list-utilities-secondary i { position: absolute; top: -3px; left: 0; }

.list-utilities { padding: 0; list-style-type: none; }
.list-utilities li + li { margin-top: 23px; }

@media(max-width: 767px){
	
	/* ------------------------------------------------------------ *\
		Utilities
	\* ------------------------------------------------------------ */

	.utilities h1 { font-size: 14px; }
	
	.list-utilities a { font-size: 12px; }
	.list-utilities-secondary a { padding-left: 20px; }

	.list-utilities li + li { margin-top: 5px; }
}