/* ------------------------------------------------------------ *\
	Base
\* ------------------------------------------------------------ */

body { min-width: 320px; }

h2 { font-weight: 700; letter-spacing: 0.03em; color: #0072ba; }
h3 { font-weight: 700; color: #0070ad; }

a:focus { outline: 0; }

@media(max-width: 767px){
	

	/* ------------------------------------------------------------ *\
		Base
	\* ------------------------------------------------------------ */
	
	h2 { font-size: 17px; }
}