/* ------------------------------------------------------------ *\
	Article
\* ------------------------------------------------------------ */

.article { padding-top: 28px; }

.article h2 { font-family: 'Helvetica', sans-serif; font-size: 24px; line-height: 1.42; font-weight: 400; letter-spacing: 0; color: #575757; margin-top: 0; padding-bottom: 17px; }
.article p { padding-bottom: 34px; font-size: 16px; line-height: 1.5; font-weight: 500; color: #000; }
.article-image { margin-bottom: 17px; }
.article h1 { font-size: 30px; font-weight: 700; letter-spacing: 0.03em; color: #0072ba; margin-bottom: 5px; }
.article a { display: block; padding-top: 6px; font-size: 24px; font-weight: 500; color: #0072ba; }

.article img { margin-bottom: 24px; }


@media(max-width: 1200px){
	
	/* ------------------------------------------------------------ *\
		Article
	\* ------------------------------------------------------------ */
	
	.article img { width: 100%; height: auto; }
}

@media(max-width: 767px){
	
	/* ------------------------------------------------------------ *\
		Article
	\* ------------------------------------------------------------ */
	
	.article { padding-top: 0; }

	.article h2 {font-size: 12px; line-height: 1.5; padding-bottom: 3px; }
	.article img { margin-bottom: 5px; }
	.article .row img { margin: 0; }
	.article h1 { font-size: 18px; line-height: 22px; margin-bottom: 2px; }
	.article p { padding-bottom: 7px; font-size: 10px; line-height: 1.4; }
	.article a { font-size: 12px; }

	.article .row { padding-bottom: 6px; }
	.article .col-sm-6 + .col-sm-6 { margin-top: 15px; }
}