/* ------------------------------------------------------------ *\
	Sidebar
\* ------------------------------------------------------------ */

.sidebar { padding-top: 53px; }

@media(max-width: 767px){
	
	/* ------------------------------------------------------------ *\
		Sidebar
	\* ------------------------------------------------------------ */
	
	.sidebar { padding-top: 12px; }
}