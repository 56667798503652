/* ------------------------------------------------------------ *\
	Main
\* ------------------------------------------------------------ */

.main { position: relative; padding-bottom: 69px; background: #eff1f3; z-index: 5; }

.main-inner { background: #fff; }

/* ------------------------------------------------------------ *\
	Main Secondary
\* ------------------------------------------------------------ */

.main-secondary { background: #fff; }

@media(max-width: 992px){
	
	/* ------------------------------------------------------------ *\
		Main
	\* ------------------------------------------------------------ */
	
	.main { padding-bottom: 20px; }
}

@media(max-width: 767px){
	
	/* ------------------------------------------------------------ *\
		Main
	\* ------------------------------------------------------------ */
	
	.main { position: static; }
}