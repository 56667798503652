/* ------------------------------------------------------------ *\
	Contact
\* ------------------------------------------------------------ */

.contacts h1 { font-size: 24px; color: #0072ba; font-weight: 500; margin: 0 0 27px; }
.contacts p { margin-bottom: 19px; font-size: 16px; line-height: 1.5; font-weight: 500; color: #000; }

.contacts .phone { margin-bottom: 20px; font-size: 16px; font-weight: 700; letter-spacing: 0.03em; color: #000; }
.contacts .phone a { color: #000; }

.contacts .email { font-size: 16px; color: #0072ba; }
.contacts .email a { color: #0072ba; }

@media(max-width: 767px){
	
	/* ------------------------------------------------------------ *\
		Contact
	\* ------------------------------------------------------------ */
	
	.contacts h1 { font-size: 16px; margin-bottom: 25px; }
	.contacts p { font-size: 12px; line-height: 1.5; }

	.contacts .email,
	.contacts .phone { font-size: 12px; }
}