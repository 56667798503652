/* ------------------------------------------------------------ *\
	Feature
\* ------------------------------------------------------------ */

.feature { position: relative; border: 1px solid #dedede; }

.feature img { width: 100%; height: auto; }

.feature .feature-image span { position: absolute; top: 21px; left: 0; display: inline-block; padding: 5px 36px 4px 31px; border-radius: 0 3px 3px 0; font-size: 16px; font-weight: 500; color: #fff; text-align: center; background: #ff9500; }
.feature .feature-image.red span{ background: #e95e4f; }
.feature h1 { font-size: 16px; font-weight: 700; color: #0072ba; margin: 0 0 11px; }
.feature-content { padding: 30px 18px 16px 35px; border-top: 1px solid #dedede; background: #fff; }
.feature p { font-size: 16px; line-height: 1.69; color: #636364; }
.feature-inner { padding: 18px 36px 22px; border-top: 1px solid #dedede; background: #0072ba; }
.feature-inner a { font-size: 16px; font-weight: 700; color: #fff; text-decoration: none; }
.feature-inner a:hover { color: #fff; text-decoration: underline; }

@media(max-width: 992px){
	
	/* ------------------------------------------------------------ *\
		Feature
	\* ------------------------------------------------------------ */
	
	.feature-content { padding: 15px; }
	.feature-inner { padding: 8px 17px; }
}

@media(max-width: 767px){
	
	/* ------------------------------------------------------------ *\
		Feature
	\* ------------------------------------------------------------ */
	
	.feature .feature-image span { top: 17px; font-size: 10px; padding: 1px 18px 3px; }
	.feature-content { padding: 24px 27px 10px; }
	.feature-inner { padding: 12px 29px 17px; }
	.feature h1 { font-size: 12px; margin-bottom: 9px; }
	.feature p { font-size: 12px; line-height: 1.84; }
	.feature-inner a { font-size: 12px; }
}