/* ------------------------------------------------------------ *\
	Subscribe
\* ------------------------------------------------------------ */

input::placeholder { color: inherit; opacity: 1; }
::-webkit-input-placeholder { color: inherit; }
:-moz-placeholder { color: inherit; opacity: 1; }
::-moz-placeholder { color: inherit; opacity: 1; }
:-ms-input-placeholder { color: inherit; }

.subscribe { position: relative; max-width: 293px; margin: -2px 0 53px; }

.subscribe label { display: block; margin-bottom: 16px; font-size: 15px; line-height: 1.6; font-weight: 400; color: #fff; }
.subscribe-field { width: 100%; height: 49px; padding: 0 35px 0 21px; border: 0; border-radius: 0; font-size: 14px; font-weight: 500; color: #000; background: #e1e1e1; }
.subscribe-btn { position: absolute; bottom: 14px; right: 8px; border: 0; border-radius: 0; background: none; }
.subscribe-btn:hover { opacity: .7; }

@media(max-width: 767px){
	
	/* ------------------------------------------------------------ *\
		Subscribe
	\* ------------------------------------------------------------ */
	
	.subscribe { max-width: 234px; margin: -1px 0 26px; }

	.subscribe label { font-size: 12px; line-height: 1.58; }
	.subscribe-field { height: 39px; font-size: 11px; }
	.subscribe-btn { bottom: 9px; }
}