/* ------------------------------------------------------------ *\
	Intro
\* ------------------------------------------------------------ */

.intro { padding: 75px 0 84px; border-bottom: 1px solid #b7babe; background-position: center center;  
	-webkit-background-size: cover; 
	        background-size: cover; 
}

.intro h1 { font-size: 30px; font-weight: 700; letter-spacing: 0.03em; color: #fff; }
.intro-bg { display: none; }

/* ------------------------------------------------------------ *\
	Intro Secondary
\* ------------------------------------------------------------ */

.intro-secondary { border: 0; }

@media(max-width: 767px){
	
	/* ------------------------------------------------------------ *\
		Intro
	\* ------------------------------------------------------------ */
	
	.intro { padding: 18px 0 27px; }
	
	.intro h1 { font-size: 12px; }
}